import { lazy, Suspense, useMemo } from 'react'
import { Box, Divider, styled, Tab, Tabs, Typography } from '@mui/material'

import { Fallback, useTabController } from '@klr/shared'

import { useCustomerStore } from '../../../../../../store'

const CustomerBooking = lazy(() => import('./components/CustomerBooking'))
const CustomerOrderList = lazy(() => import('./components/CustomerOrderList'))
const CustomerTripList = lazy(() => import('./components/CustomerTripList'))
const CustomerNotes = lazy(() => import('./components/CustomerNotes'))
const CustomerSocialNetworks = lazy(() => import('./components/CustomerSocialNetworks'))
const CustomerLoyalty = lazy(() => import('./components/CustomerLoyalty'))

const loyaltyTab = 'Програма лояльності'

const CustomerTabContent = ({ currentTab }: { currentTab: number }) => {
  switch (currentTab) {
    case 0:
      return <CustomerBooking />
    case 1:
      return <CustomerOrderList />
    case 2:
      return <CustomerTripList />
    case 3:
      return <CustomerNotes />
    case 4:
      return <CustomerSocialNetworks />
    case 5:
      return <CustomerLoyalty />
    default:
      return null
  }
}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  borderRadius: 16,
}))

export const CustomerInfoTabs = () => {
  const { currentTab, handleTabsChange } = useTabController(0)

  const hasLoyaltyActive = useCustomerStore((state) => state.mainInfo?.loyalty_active)

  const tabs = useMemo(() => {
    const initialTabList = [
      'Бронювання',
      'Замовлення',
      'Поїздки',
      'Примітки',
      'Соцмережі',
      loyaltyTab,
    ]

    if (hasLoyaltyActive) return initialTabList

    return initialTabList.filter((item) => item !== loyaltyTab)
  }, [hasLoyaltyActive])

  return (
    <StyledBox>
      <Tabs
        value={currentTab}
        onChange={handleTabsChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((item, index) => (
          <Tab
            key={index}
            label={<Typography variant="caption">{item}</Typography>}
            value={index}
          />
        ))}
      </Tabs>

      <Divider sx={{ mb: 2 }} />

      <Suspense fallback={<Fallback />}>
        <CustomerTabContent currentTab={currentTab} />
      </Suspense>
    </StyledBox>
  )
}
