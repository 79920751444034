import { useMemo } from 'react'
import { Box, Stack, Tooltip, Typography } from '@mui/material'

import { SheetDefaultMainPrice } from '@klr/api-connectors'

import { SurchargePrice } from './SurchargePrice'

type PriceViewProps = {
  price: SheetDefaultMainPrice | null
  surchargePrice: number
  setEditMode: (val: boolean) => void
}

export const PriceView = ({ price, setEditMode, surchargePrice }: PriceViewProps) => {
  const tooltipTitle = useMemo(() => {
    if (price) {
      if (price.price_discount === price.price_full) {
        return 'Повна ціна'
      }

      return 'Остаточна ціна'
    }

    return ''
  }, [price])

  if (!price || !price.price_full) {
    return null
  }

  if (price.price_full === price.price_discount) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Tooltip
          title={tooltipTitle}
          aria-label={tooltipTitle}
          sx={{ cursor: 'pointer' }}
          onClick={() => setEditMode(true)}
        >
          <Typography color="primary">
            {price.price_full} {price.currency?.title || ''}
          </Typography>
        </Tooltip>
        {surchargePrice > 0 && (
          <SurchargePrice
            currencyTitle={price.currency?.title || ''}
            surchargePrice={surchargePrice}
          />
        )}
      </Stack>
    )
  }

  return (
    <Stack direction="row" spacing={1}>
      <Box>
        <Tooltip title={tooltipTitle} aria-label={tooltipTitle}>
          <Typography component="span" color="primary">
            {price.price_discount} {price.currency?.title || ''}
          </Typography>
        </Tooltip>
        <Tooltip title="Початкова ціна" aria-label="Початкова ціна">
          <Typography
            variant="body2"
            component="span"
            sx={{
              ml: 2,
            }}
          >
            ({price.price_full} {price.currency?.title || ''})
          </Typography>
        </Tooltip>
      </Box>
      {surchargePrice > 0 && (
        <SurchargePrice
          currencyTitle={price.currency?.title || ''}
          surchargePrice={surchargePrice}
        />
      )}
    </Stack>
  )
}
