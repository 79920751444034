import { create } from 'zustand'
import { devtools, subscribeWithSelector } from 'zustand/middleware'

import { BookingItem, BookingReqFetchList, DateType } from '@klr/api-connectors'
import { createSelectors } from '@klr/shared'

import { BookingResFetchListStatus } from '../booking.types'
import { getSortedTripList } from '../utils/booking.utils'

interface BookingItemWithDifferentDates {
  date: DateType
  items: BookingItem[]
}

interface BookingSearchStore {
  internalTrips: BookingItem[]
  externalTrips: BookingItem[]
  otherTrips: BookingItemWithDifferentDates[]
  tripRequest: BookingReqFetchList | null
  status: BookingResFetchListStatus
  initialDate: string
  sortedInternalTrips(): BookingItem[]
  sortedOtherTrips(): BookingItem[]
}

export const useBookingSearchStore = createSelectors(
  create<BookingSearchStore>()(
    devtools(
      subscribeWithSelector((_, get) => ({
        internalTrips: [],
        externalTrips: [],
        otherTrips: [],
        tripRequest: null,
        status: null,
        initialDate: '',
        sortedInternalTrips: () => getSortedTripList(get().internalTrips),
        sortedOtherTrips: () =>
          get()
            .otherTrips.map((item) => ({ ...item, items: getSortedTripList(item.items) }))
            .flatMap((item) => item.items),
      })),
      {
        name: 'bookingStore',
        enabled: process.env.NODE_ENV === 'development',
      }
    )
  )
)

export const setInternalTrips = (payload: BookingItem[]) =>
  useBookingSearchStore.setState(() => ({ internalTrips: payload }))

export const setExternalTrips = (payload: BookingItem[]) =>
  useBookingSearchStore.setState(() => ({ externalTrips: payload }))

export const setOtherTrips = (payload: BookingItemWithDifferentDates[]) =>
  useBookingSearchStore.setState(() => ({ otherTrips: payload }))

export const setTripRequest = (payload: BookingReqFetchList) =>
  useBookingSearchStore.setState(() => ({ tripRequest: payload }))

export const setStatus = (payload: BookingResFetchListStatus) =>
  useBookingSearchStore.setState(() => ({ status: payload }))

export const setInitialDate = (payload: string) =>
  useBookingSearchStore.setState(() => ({ initialDate: payload }))
