import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Divider, Grid, Skeleton } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'

import {
  OrderDetail,
  ordersQueryKeys,
  TicketAvailableSeat,
  TicketDetail,
  TicketReqChangeData,
} from '@klr/api-connectors'
import {
  formattingPhoneNumber,
  FormProvider,
  GenericTextFields,
  RHFPhoneInputMask,
  RHFTextField,
  useConfirm,
} from '@klr/shared'

import { RHFPlaceAutocomplete } from '../../../sheets/components/CreateTicketForms'
import { AvailablePlace } from '../../../sheets/validations'
import { useUpdateTicket } from '../../hooks'
import { AutocompleteFormPaid } from '../AutocompleteFormPaid'

import { formFields } from './config'
import { changeTicketSchema, FormFields } from './validationSchema'

interface FormChangeTicketDetailProps {
  source: 'sheet' | 'order-list' | 'order-report'
  isAllowEdit?: boolean
  isFixedPlaceNumber: boolean
  isLoading: boolean
  ticketId: TicketDetail['id']
  orderId: OrderDetail['id']
  loadingAvailablePlaces?: boolean
  availablePlaces?: TicketAvailableSeat[]
  defaultFirstName: string
  defaultLastName: string
  defaultPhone: string
  defaultEmail: string
  defaultTicketNumber: string
  defaultNote: TicketDetail['information']
  defaultPlace?: AvailablePlace | null
  defaultPaidFormId?: string | null
  defaultPaidFormName?: string
}

export const FormChangeTicketDetail = ({
  source,
  isAllowEdit = true,
  isFixedPlaceNumber,
  isLoading,
  ticketId,
  orderId,
  loadingAvailablePlaces = false,
  availablePlaces = [],
  defaultFirstName,
  defaultLastName,
  defaultPhone,
  defaultEmail,
  defaultTicketNumber,
  defaultNote,
  defaultPlace = null,
  defaultPaidFormId = null,
  defaultPaidFormName,
}: FormChangeTicketDetailProps) => {
  const confirm = useConfirm()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const successCallback = useMemo(() => {
    switch (source) {
      case 'sheet':
        return async () => {
          await queryClient.invalidateQueries(['sheet'])
        }
      case 'order-list':
        return (val: TicketDetail) => {
          queryClient.setQueryData(
            ordersQueryKeys.ordersList({ ticket_phone: defaultPhone }),
            (oldData?: OrderDetail[]) =>
              oldData?.map((item) => {
                if (item.id === orderId) {
                  return {
                    ...item,
                    tickets: item.tickets.map((ticket) => {
                      if (ticket.id === val.id) {
                        return val
                      }

                      return ticket
                    }),
                  }
                }

                return item
              }) ?? []
          )
        }
      case 'order-report':
        return async () => {
          await queryClient.invalidateQueries(ordersQueryKeys.detail(orderId))
        }
      default:
        return undefined
    }
  }, [defaultPhone, orderId, queryClient, source])

  const { mutate, isLoading: isUpdating } = useUpdateTicket(successCallback)

  const formAvailablePlaces: AvailablePlace[] = availablePlaces.map((item) => ({
    place: item.seat,
    category: item.category,
    disabled: false,
  }))

  const methods = useForm<FormFields>({
    defaultValues: {
      firstName: defaultFirstName,
      lastName: defaultLastName,
      phone: formattingPhoneNumber(defaultPhone),
      email: defaultEmail,
      ticketNumber: defaultTicketNumber,
      note: defaultNote ?? '',
      place: defaultPlace,
      paidForm: {
        id: defaultPaidFormId,
        name: defaultPaidFormName,
      },
    },
    resolver: yupResolver(changeTicketSchema(t)),
  })

  const { handleSubmit } = methods

  const _handleSubmit = (fields: FormFields): void => {
    const data: TicketReqChangeData = {
      first_name: fields.firstName,
      last_name: fields.lastName,
      phone: fields.phone,
      email: fields.email,
      ticket_number: fields.ticketNumber,
      information: fields.note,
    }

    if (fields.paidForm) {
      data.paid_form = fields.paidForm.id ?? undefined
    }

    if (fields.place) {
      data.place = fields.place.place
    }

    mutate({
      ticketId,
      data,
    })
  }

  const handleChange = (fields: FormFields): void => {
    if (!isFixedPlaceNumber || defaultPlace === fields.place) {
      _handleSubmit(fields)
    } else {
      confirm({
        title: t('Common.fixed_place_dialog_title'),
      }).then((success) => {
        if (success) {
          _handleSubmit(fields)
        }
      })
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleChange)}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} container justifyContent="space-between" spacing={2}>
          {defaultPaidFormId ? (
            <Grid item xs={12} sm={6}>
              <AutocompleteFormPaid
                name="paidForm"
                defaultOrderStatus={{
                  id: defaultPaidFormId,
                  name: defaultPaidFormName || '',
                }}
              />
            </Grid>
          ) : null}

          {!loadingAvailablePlaces ? (
            defaultPlace && (
              <Grid item sm={6}>
                <RHFPlaceAutocomplete
                  name="place"
                  options={[defaultPlace, ...formAvailablePlaces]}
                  textFieldProps={{
                    size: 'medium',
                    fullWidth: false,
                  }}
                />
              </Grid>
            )
          ) : (
            <Skeleton variant="rectangular" width="100%" height={52} />
          )}
        </Grid>

        <Grid item xs={12}>
          <Box mb={1} mt={1}>
            <Divider />
          </Box>
        </Grid>

        <GenericTextFields formFields={formFields} isDisabled={!isAllowEdit} />

        <Grid item xs={12} sm={6}>
          <RHFPhoneInputMask name="phone" label="Номер телефону" disabled={!isAllowEdit} />
        </Grid>

        <Grid item sm={12}>
          <RHFTextField
            label="Примітка"
            name="note"
            multiline
            rows={3}
            disabled={isLoading || !isAllowEdit || isUpdating}
          />
        </Grid>

        {isAllowEdit && (
          <Grid item container justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              disabled={isLoading || !methods.formState.isDirty || isUpdating}
              loading={isLoading}
              type="submit"
            >
              Зберегти
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  )
}
