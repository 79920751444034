import { CSSProperties } from 'react'
import { Box, Card, Divider, Grid, Theme } from '@mui/material'
import { styled } from '@mui/material/styles'

import { customShadows, RecommendCarrierIcon, RecommendIcon } from '@klr/shared'

export const StyledCardTripRoot = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(3),
  boxShadow: customShadows(theme.palette.mode).z1,
  borderRadius: theme.shape.borderRadius * 2,
}))

export const StyledCardTripCard = styled(Card)(() => ({
  touchAction: 'manipulation',
  padding: 0,
  boxShadow: 'none',
  borderRadius: 12,
  overflow: 'visible',

  position: 'relative',
}))

export const StyledInfoGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 2, 1),
  },
}))

export const StyledCardTripDivider = styled(Divider)(() => ({
  position: 'absolute',
  top: 0,
  right: '33.333333%',
  height: '100%',
  border: '1px dashed #8e93a1',
  opacity: 0.7,
}))

const recommendStyles = (theme: Theme): CSSProperties => {
  return {
    fill: theme.palette.primary.dark,
    position: 'absolute',
    top: 0,
    width: 18,
    height: 36,
    right: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      right: '36%',
    },
  }
}

export const StyledRecommendIcon = styled(RecommendIcon)(({ theme }) => ({
  ...recommendStyles(theme),
}))

export const StyledRecommendCarrierIcon = styled(RecommendCarrierIcon)(({ theme }) => ({
  color: theme.palette.success.lighter,
  ...recommendStyles(theme),
}))
