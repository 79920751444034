import { useDispatch, useSelector } from 'react-redux'
import {
  List as ListIcon,
  PersonAdd as PersonAddIcon,
  Search as SearchIcon,
} from '@mui/icons-material'
import { AppBar, Box, Divider, IconButton, Stack, SvgIcon, Toolbar, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { AccountPopover, InviteLinkModalButton } from '@klr/auth'
import { toggleSearchCustomerDrawer } from '@klr/customers'
import { bgBlur, HEADER, OnlineStatus, SettingsMenu, SvgColor } from '@klr/shared'

import { sheetsSelectors } from '../../../../_modules_/sheets'
import { useShowCreateTicketQuick } from '../../../../_modules_/sheets/hooks'
import { DrawerSearchPassenger } from '../../../../_modules_/sheets/layout-components/DrawerSeacrhPassenger'
import {
  setPassengerPhoneNumber,
  toggleSearchPassengerDrawer,
} from '../../../../_modules_/sheets/store'
import { uiActions } from '../../../../_modules_/ui'
import { DetailViewEnum } from '../../../../constants/detail-view.enum'
import { environments } from '../../../../environments'
import { InitChangelogButton, InitFeedbackButton } from '../../../../features/featurebase'

import { CustomerSection } from './components/CustomerSection'

type Props = {
  onOpenNav?: VoidFunction
}

export const Header = ({ onOpenNav }: Props) => {
  const theme = useTheme()

  const dispatch = useDispatch()

  const selectTransport = useSelector(sheetsSelectors.selectTransport)
  const showCreateTicketQuick = useShowCreateTicketQuick()

  const showRoute = () => {
    dispatch(
      uiActions.showDetailView({
        meta: DetailViewEnum.SEARCH_SHEET,
      })
    )
  }

  const showSearchPassenger = (payload: string) => {
    toggleSearchPassengerDrawer(true)
    toggleSearchCustomerDrawer(false)
    setPassengerPhoneNumber(payload)
  }

  const renderContent = (
    <>
      <IconButton onClick={onOpenNav}>
        <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
      </IconButton>

      <Box ml={15}>
        <CustomerSection onShowSearchPassenger={showSearchPassenger} />
      </Box>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <OnlineStatus />

        <InitChangelogButton />

        <Tooltip title="Пошук пасажира">
          <IconButton onClick={() => showSearchPassenger('')} size="large">
            <SvgIcon>
              <SearchIcon />
            </SvgIcon>
          </IconButton>
        </Tooltip>

        {selectTransport && (
          <Tooltip title="Добавити пасажира">
            <IconButton onClick={showCreateTicketQuick} size="large">
              <SvgIcon>
                <PersonAddIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Пошук маршруту">
          <IconButton onClick={showRoute} size="large">
            <SvgIcon>
              <ListIcon />
            </SvgIcon>
          </IconButton>
        </Tooltip>

        <InviteLinkModalButton marketplaceURL={environments.marketplaceURL} />

        <SettingsMenu />

        <AccountPopover
          slotAdditional={
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />

              <InitFeedbackButton />
            </>
          }
        />
      </Stack>
      <DrawerSearchPassenger />
    </>
  )

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  )
}
