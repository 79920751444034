import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

import { OrderStatus } from '@klr/api-connectors'

const StyledChip = styled(Chip)(({ theme }) => ({
  border: `1px dashed ${theme.palette.action.active}`,
  color: theme.palette.action.active,
  marginTop: 6,
  marginRight: 6,
}))

type Props = {
  quickArrayFormPaid?: OrderStatus[]
  handleChange: (val: { id: string; name: string }) => void
}

const chips: OrderStatus[] = [
  {
    id: 'reserved',
    name: 'Резервація',
  },
  {
    id: '26',
    name: 'Вояжер',
  },
  {
    id: '23',
    name: 'Євротікет',
  },
]

export const ChipsFormPaid = ({ handleChange, quickArrayFormPaid = chips }: Props) => {
  return (
    <>
      {quickArrayFormPaid.map((item, idx) => (
        <StyledChip
          key={idx}
          tabIndex={-1}
          label={item.name}
          size="small"
          onClick={() => handleChange(item)}
        />
      ))}
    </>
  )
}
